import React, { useRef, useState, useEffect, Suspense, useMemo } from "react";
import { ClassLikeDeclaration } from "typescript";

const ClickNotification = () => {
    return(
        <div className="clickNotification">
            <p>CLICK</p>
            <div className="clickNotificationCircle" id="circle1"></div>
            <div className="clickNotificationCircle" id="circle2"></div>
        </div>
    )
}

export default ClickNotification;