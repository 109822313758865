import React, { useRef, useState } from "react";
// import "./Main.css";
import TabHome from "./TabHome";
import { CSSTransition } from "react-transition-group";
import TabContainer from "./TabContainer";
import { useFrame } from "react-three-fiber";
import ClickNotification from "./ClickNotification";

const lightPoints = {
  spawnx: -10,
  spawny: 3,
  spawnz: 4,
  targetx: 10,
};

var movingLightDirection = "plus";
var lightIntensity = "plus";

const Main = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [tabIsTransitioning, setTabIsTransitioning] = useState(false);

  // const [modelOpen, setModelOpen] = useState(false);

  // const switchOpenClose = () => {
  //   setModelOpen(!modelOpen)
  // }

  const endTabTransition = () => {
    setTabIsTransitioning(false);
  };

  const selectTab = (tabNumber: number) => {
    if (currentTab !== tabNumber) {
      setCurrentTab(tabNumber);
      setTabIsTransitioning(true);

      setTimeout(endTabTransition, 1000);
    }
  };

  const formatTabContainer = () => {
    switch (currentTab) {
      default:
        return (
          <CSSTransition
            in={tabIsTransitioning}
            timeout={1000}
            classNames="tab"
            appear
            enter={true}
          >
            <div className="tabHome">
              <ClickNotification />
              <TabHome
                tabIsTransitioning={tabIsTransitioning}
                // modelOpen={modelOpen}
                // setModelOpen={switchOpenClose}
              />
            </div>
          </CSSTransition>
        );

      case 0:
        return (
          <CSSTransition
            in={tabIsTransitioning}
            timeout={1000}
            classNames="tab"
            appear
            enter={true}
          >
            <div className="tabHome">
              <ClickNotification />
              <TabHome
                tabIsTransitioning={tabIsTransitioning}
                // modelOpen={modelOpen}
                // setModelOpen={switchOpenClose}
              />
            </div>
          </CSSTransition>
        );
      case 1:
        return (
          <CSSTransition
            in={tabIsTransitioning}
            timeout={1000}
            classNames="tab"
            appear
            enter={true}
          >
            <TabContainer currentTab={currentTab} />
          </CSSTransition>
        );
      case 2:
        return (
          <CSSTransition
            in={tabIsTransitioning}
            timeout={1000}
            classNames="tab"
            appear
            enter={true}
          >
            <TabContainer currentTab={currentTab} />
          </CSSTransition>
        );
      case 3:
        return (
          <CSSTransition
            in={tabIsTransitioning}
            timeout={1000}
            classNames="tab"
            appear
            enter={true}
          >
            <TabContainer currentTab={currentTab} />
          </CSSTransition>
        );
    }
  };

  // componentDidUpdate = (prevProps: any, prevState: any) => {
  //   if (currentTab !== prevState.currentTab) {

  //       this.setState({
  //         loadImage: false
  //       })
  //     }
  // };

  return (
    <div>
      <div id="navbar">
        <div id="title" onClick={() => selectTab(0)}>
          <h1>Octav</h1>
          <h1>Codrea</h1>
        </div>

        <div id="navbar-links">
          <ul>
            <li onClick={() => selectTab(1)}>Read</li>
            <li onClick={() => selectTab(2)}>Gallery</li>
            <li onClick={() => selectTab(3)}>Contact</li>
          </ul>
        </div>
      </div>

      {formatTabContainer()}
    </div>
  );
};

export default Main;
