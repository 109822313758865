import React, { useRef, useState, Suspense, useMemo, useEffect } from "react";
import * as THREE from "three";
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Canvas, useFrame, useLoader } from "react-three-fiber";
import Model from "./AndroidModel";
import ClickNotification from "./ClickNotification";
import MovingLight from "./MovingLight";

interface TabHomeProps {
  // modelOpen: boolean,
  // setModelOpen: Function
  tabIsTransitioning: boolean;
}

const TabHome = (props: TabHomeProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [timeoutStarted, setTimeoutStarted] = useState(false);

  useEffect(() => {
    if (timeoutStarted === false) {
      setTimeoutStarted(true);
    }
  });

  return (
    <Canvas>
      <MovingLight />

      <pointLight position={[10, 10, 10]} color={"#C08984"} intensity={0.4} />

      <Model
        scale={[1.5, 1.5, 1.5]}
        tabIsTransitioning={props.tabIsTransitioning}
      />

      {/* <ambientLight
              color={'#ffffff'}
              intensity={0.1}
            /> */}

      {/* <pointLight 
              position={[-10, 10, 10]} 
              color={'#ffffff'}
              intensity={0.2}/> */}

      {/* <pointLight 
              position={[-10, 10, 10]} 
              color={'#a84'}/> */}
    </Canvas>
  );
};

export default TabHome;
