import React, { useRef } from "react";
import * as THREE from "three";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useFrame } from "react-three-fiber";

const lightPoints = {
  spawnx: -10,
  spawny: 3,
  spawnz: 4,
  targetx: 10,
};

var movingLightDirection = "plus";
var lightIntensity = "plus";

const MovingLight = (props: any) => {
  // console.log("moving light");
  const lightRef = useRef<THREE.PointLight>();

  useFrame(() => {
    if (lightRef.current !== undefined && lightRef.current !== null) {
      if (movingLightDirection === "plus") {
        lightRef.current.position.x = lightRef.current.position.x + 0.03;
      } else {
        lightRef.current.position.x = lightRef.current.position.x - 0.03;
      }

      if (lightIntensity === "plus") {
        lightRef.current.intensity = lightRef.current.intensity + 0.01;
      } else {
        if (lightRef.current.intensity > 0.01)
          lightRef.current.intensity = lightRef.current.intensity - 0.01;
      }

      if (Math.abs(lightRef.current.position.x - lightPoints.targetx) < 1) {
        setLightPoints();
      }

      if (Math.abs(lightRef.current.position.x) < 1) {
        lightIntensity = "minus";
      }
      // console.log(lightRef.current.position.x)
    }
  });

  const setLightPoints = () => {
    lightPoints.spawnx = 10 - Math.random() * 20;
    lightPoints.spawny = 5 - Math.random() * 10;

    if (Math.abs(lightPoints.spawnx) < 3) {
      lightPoints.spawnx += 5;
    }

    lightPoints.targetx = lightPoints.spawnx * -1;
    if (lightPoints.targetx > lightPoints.spawnx) {
      movingLightDirection = "plus";
    } else {
      movingLightDirection = "minus";
    }

    if (lightRef.current !== undefined && lightRef.current !== null) {
      lightRef.current.position.x = lightPoints.spawnx;
      lightRef.current.position.y = lightPoints.spawny;

      lightRef.current.position.z = lightPoints.spawnz;
      lightRef.current.intensity = 0;
    }
    lightIntensity = "plus";
  };
  setLightPoints();

  return (
    //ddaa55
    //eecc66
    <pointLight
      ref={lightRef}
      distance={5}
      color={"#aaa"}
      castShadow
      {...props}
    />
  );
};

export default MovingLight;
