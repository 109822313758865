import React, { useState } from "react";

import songvis1 from "./images/songvis/a.png";
import songvis2 from "./images/songvis/b.png";
import songvis3 from "./images/songvis/c.png";
import songvis4 from "./images/songvis/d.png";

import plannr1 from "./images/plannr/1.png";
import plannr2 from "./images/plannr/2.png";

import sol1 from "./images/solaris/a1.png";
import sol2 from "./images/solaris/a2.png";
import sol3 from "./images/solaris/a3.png";
import sol4 from "./images/solaris/a4.png";

import ss1 from "./images/sol-system/ss1.png";

const contentResume = () => {
  return (
    <div className="tab">
      <h4>Work</h4>
      <div>
        <div className="resumePair">
          <div className="pairItem">
            <h5>Webmarc</h5>
            11.2020 - present
          </div>

          <div className="pairItem">
            <h5>Frontend Developer</h5>
            <ul>
              <li>working on a yet undisclosed project</li>
            </ul>
          </div>
        </div>

        <div className="resumePair">
          <div className="pairItem">
            <h5>LudicMind Entertainment</h5>
            12.2018 - 10.2020
          </div>

          <div className="pairItem">
            <h5>Junior 2D/3D Artist</h5>
            <ul>
              <li>
                creating 2D and 3D assets for characters and environments based
                on a given theme, story or on an already established IP
              </li>
              <li>
                animating 2D assets and assembling environments from modular
                pieces
              </li>
              <li>creating UI elements in theme with the project</li>
              <li>modeling and animating 3D assets for a in-house project</li>
            </ul>
          </div>
        </div>

        <div className="resumePair">
          <div className="pairItem">
            <h5>Asociația Clasa Întâi</h5>
            05.2019 - 10.2020
          </div>
          <div className="pairItem">
            <h5>Illustrator / Graphic Designer </h5>
            <ul>
              <li>illustrating characters for children's media</li>
              <li>designing posters and flyers for live events </li>
              <li>
                designing graphics for social media, web, stationery and
                merchandise{" "}
              </li>
            </ul>
          </div>
        </div>

        <div className="resumePair">
          <div className="pairItem">
            <h5>Ubisoft Entertainment</h5>
            06.2015 - 09.2015
          </div>
          <div className="pairItem">
            <h5>Video Game Tester </h5>
            <ul>
              <li>performing basic functionality tests</li>
              <li>identifying and reporting issues using Atlassian JIRA</li>
              <li>performing daily regression tests and submitting reports</li>
            </ul>
          </div>
        </div>
      </div>

      <h4>Education</h4>
      <div>
        <div className="resumePair">
          <div className="pairItem">
            <h5>Bachelor's Degree </h5>
            Graphic Art <br />
            2016 - 2019
          </div>
          <div className="pairItem">
            Faculty of Fine Arts <br />
            National University of Arts <br />
            Bucharest, Romania
          </div>
        </div>

        <div className="resumePair">
          <div className="pairItem">
            <h5>Bachelor's Degree </h5>
            Foreign Exchange Program <br />
            Jan 2018 - June 2018
          </div>
          <div className="pairItem">
            Faculty of Art and Design <br />
            Jan Evangelista Purkyně University <br />
            Ústí nad Labem, Czech Republic
          </div>
        </div>

        <div className="resumePair">
          <div className="pairItem">
            <h5>Bachelor's Degree</h5>
            English/Swedish Philology <br />
            2014 - 2017
          </div>
          <div className="pairItem">
            Faculty of Foreign Languages & Literature <br />
            University of Bucharest <br />
            Bucharest, Romania
          </div>
        </div>

        <div className="resumePair">
          <div className="pairItem">High School</div>
          <div className="pairItem">Gheorghe Șincai National College</div>
        </div>
      </div>

      <h4>Skills</h4>
      <div className="pairItem">
        Adobe Photoshop, Adobe Illustrator, <br />
        HTML & CSS, SASS, JavaScript, <br />
        React, TypeScript, Three.js <br />
        Bootstrap, Adobe XD, Blender 3D
      </div>

      <h4>Other things I'm sorta good at</h4>
      <div className="pairItem">
        Drawing & Illustration, Design, UX & UI, <br />
        Video Game Development, 2D Animation
      </div>
    </div>
  );
};

const contentWebProjects = () => {
  return (
    <div className="tab">
      <h3>Web Projects</h3>

      <div>
        <h4>
          Solaris{" "}
          <a
            href="https://solaris.octavcodrea.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            [Link]
          </a>
        </h4>
        <div className="projectDescription">
          <p>
            Solaris is a piece of science-fiction themed audio-visual media that
            runs as single-page web app.{" "}
          </p>
          <p>
            The graphics and animation are both rendered in the webpage and the
            project is a collaboration between two other artists working on
            narration and sound design while I worked on the visual design and
            programming.
          </p>
        </div>
        <div className="projectGallery">
          <div className="imageContainer">
            <img src={sol1} alt="" />
          </div>
          <div className="imageContainer">
            <img src={sol2} alt="" />
          </div>
          <div className="imageContainer">
            <img src={sol3} alt="" />
          </div>
          <div className="imageContainer">
            <img src={sol4} alt="" />
          </div>
        </div>
        <h5>How does it work?</h5>
        <div className="projectDescription">
          <p>
            The app is written in React with Typescript and uses the react-sound
            and react-h5-audio-player packages for playing and syncing the audio
            tracks. The graphics are svg-based and their animation and color are
            controlled using a combination of CSS and inline styles and React's
            states.
          </p>
        </div>
      </div>

      <div>
        <h4>
          Songvis{" "}
          <a
            href="https://songvis.octavcodrea.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            [Link]
          </a>
        </h4>
        <div className="projectDescription">
          <p>
            Songvis is a React Web app that generates images based on a song's
            musical features.
          </p>
          <p>
            The user can search a song and by using the Spotify API, the app
            will generate an image based on a song's features: energy, valence,
            danceability, etc. These features are generated by Spotify's song
            analysis algorithms.
          </p>
        </div>
        <div className="projectGallery">
          <div className="imageContainer">
            <img src={songvis1} alt="" />
          </div>

          <div className="imageContainer">
            <img src={songvis2} alt="" />
          </div>

          <div className="imageContainer">
            <img src={songvis3} alt="" />
          </div>

          <div className="imageContainer">
            <img src={songvis4} alt="" />
          </div>
        </div>
        <h5>How does it work?</h5>
        <div className="projectDescription">
          <p>
            Searching a song calls the Spotify API for the results. Selecting a
            song gets its features represented by float values. Using a
            combination of features, the app selects shapes and fill colors for
            them. Each of the main features has a shape and a fill gradient
            attributed to them.
          </p>
        </div>
      </div>
    </div>
  );
};

const contentContact = () => {
  return (
    <div className="tab">
      <h4>Contact</h4>
      <br />
      <h5>
        E-mail: <span>octavian.d.codrea@gmail.com</span>
      </h5>
      <br />
      <div className="socialMediaIcons">
        <div id="behance" className="socialmedia-icon">
          <a
            href="https://www.behance.net/ODCodrea"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="m181 181h-60v60h60c16.539062 0 30-13.460938 30-30s-13.460938-30-30-30zm0 0" />
              <path d="m181 271h-60v60h60c16.539062 0 30-13.460938 30-30s-13.460938-30-30-30zm0 0" />
              <path d="m346 241c-19.554688 0-36.238281 12.539062-42.4375 30h84.875c-6.199219-17.460938-22.882812-30-42.4375-30zm0 0" />
              <path d="m436 0h-361c-41.351562 0-75 33.648438-75 75v362c0 41.351562 33.648438 75 75 75h361c41.351562 0 76-33.648438 76-75v-362c0-41.351562-34.648438-75-76-75zm-150 151h120v30h-120zm-45 150c0 33.089844-26.910156 60-60 60h-90v-210h90c33.089844 0 60 26.910156 60 60 0 18.007812-8.132812 33.996094-20.730469 45 12.597657 11.003906 20.730469 26.992188 20.730469 45zm180 0h-117.4375c6.195312 17.460938 22.882812 30 42.4375 30 16.011719 0 30.953125-8.628906 38.992188-22.515625l25.957031 15.03125c-13.371094 23.113281-38.261719 37.484375-64.949219 37.484375-41.351562 0-75-33.648438-75-75s33.648438-75 75-75 75 33.648438 75 75zm0 0" />
            </svg>
          </a>
        </div>

        <div id="instagram" className="socialmedia-icon">
          <a
            href="https://www.instagram.com/octavcodrea"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="m437 0h-362c-41.351562 0-75 33.648438-75 75v362c0 41.351562 33.648438 75 75 75h362c41.351562 0 75-33.648438 75-75v-362c0-41.351562-33.648438-75-75-75zm-180 390c-74.441406 0-135-60.558594-135-135s60.558594-135 135-135 135 60.558594 135 135-60.558594 135-135 135zm150-240c-24.8125 0-45-20.1875-45-45s20.1875-45 45-45 45 20.1875 45 45-20.1875 45-45 45zm0 0" />
              <path d="m407 90c-8.277344 0-15 6.722656-15 15s6.722656 15 15 15 15-6.722656 15-15-6.722656-15-15-15zm0 0" />
              <path d="m257 150c-57.890625 0-105 47.109375-105 105s47.109375 105 105 105 105-47.109375 105-105-47.109375-105-105-105zm0 0" />
            </svg>
          </a>
        </div>

        <div id="facebook" className="socialmedia-icon">
          <a
            href="https://www.facebook.com/octavcodrea"
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="m437 0h-362c-41.351562 0-75 33.648438-75 75v362c0 41.351562 33.648438 75 75 75h151v-181h-60v-90h60v-61c0-49.628906 40.371094-90 90-90h91v90h-91v61h91l-15 90h-76v181h121c41.351562 0 75-33.648438 75-75v-362c0-41.351562-33.648438-75-75-75zm0 0" />
            </svg>
          </a>
        </div>
      </div>

      {/* <div id="linkedin" className="socialmedia-icon">
        <a
          href="https://www.linkedin.com/in/odcodrea/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="m437 0h-362c-41.351562 0-75 33.648438-75 75v362c0 41.351562 33.648438 75 75 75h362c41.351562 0 75-33.648438 75-75v-362c0-41.351562-33.648438-75-75-75zm-256 406h-60v-210h60zm0-240h-60v-60h60zm210 240h-60v-120c0-16.539062-13.460938-30-30-30s-30 13.460938-30 30v120h-60v-210h60v11.308594c15.71875-4.886719 25.929688-11.308594 45-11.308594 40.691406.042969 75 36.546875 75 79.6875zm0 0" />
          </svg>
        </a>
      </div> */}

      {/* <div id="github" className="socialmedia-icon">
        <a
          href="https://github.com/octavcodrea"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M184.7,411.7c-3.6,0.7-5.1,2.3-4.7,5c0.4,2.7,2.4,3.6,6,2.7c3.6-0.9,5.1-2.4,4.7-4.7C190.2,412.2,188.2,411.2,184.7,411.7
			z"
            />
            <path d="M165.7,414.3c-3.6,0-5.3,1.2-5.3,3.7c0,2.9,1.9,4.1,5.7,3.7c3.6,0,5.3-1.2,5.3-3.7C171.3,415.1,169.4,413.9,165.7,414.3z" />
            <path
              d="M139.7,413.3c-0.9,2.4,0.6,4.1,4.3,5c3.3,1.3,5.4,0.7,6.3-2c0.7-2.4-0.8-4.2-4.3-5.3C142.7,410.1,140.6,410.9,139.7,413.3
			z"
            />
            <path
              d="M483.8,28.2C465.1,9.4,442.4,0,416,0H96C69.6,0,46.9,9.4,28.2,28.2C9.4,46.9,0,69.6,0,96v320c0,26.4,9.4,49.1,28.2,67.8
			C46.9,502.6,69.6,512,96,512h74.7c4.9,0,8.6-0.2,11-0.5c2.4-0.3,4.9-1.8,7.3-4.3c2.4-2.6,3.7-6.3,3.7-11.2c0-0.7-0.1-8.2-0.2-22.7
			c-0.1-14.4-0.2-25.9-0.2-34.3l-7.7,1.3c-4.9,0.9-11.1,1.3-18.5,1.2c-7.4-0.1-15.2-0.9-23.2-2.3c-8-1.4-15.4-4.8-22.3-10
			c-6.9-5.2-11.8-12.1-14.7-20.5l-3.3-7.7c-2.2-5.1-5.7-10.8-10.5-17c-4.8-6.2-9.6-10.4-14.5-12.7l-2.3-1.7c-1.6-1.1-3-2.4-4.3-4
			c-1.3-1.6-2.3-3.1-3-4.7c-0.7-1.6-0.1-2.8,1.7-3.8c1.8-1,5-1.5,9.7-1.5l6.7,1c4.4,0.9,9.9,3.6,16.5,8c6.6,4.4,11.9,10.2,16.2,17.3
			c5.1,9.1,11.3,16.1,18.5,20.8c7.2,4.8,14.5,7.2,21.8,7.2s13.7-0.6,19-1.7c5.3-1.1,10.3-2.8,15-5c2-14.9,7.4-26.3,16.3-34.3
			c-12.7-1.3-24.1-3.3-34.2-6c-10.1-2.7-20.6-7-31.3-13c-10.8-6-19.7-13.4-26.8-22.3c-7.1-8.9-12.9-20.6-17.5-35
			c-4.6-14.4-6.8-31.1-6.8-50c0-26.9,8.8-49.8,26.3-68.7c-8.2-20.2-7.4-42.9,2.3-68c6.4-2,16-0.5,28.7,4.5
			c12.7,5,21.9,9.3,27.8,12.8c5.9,3.6,10.6,6.6,14.2,9c20.7-5.8,42-8.7,64-8.7c22,0,43.3,2.9,64,8.7l12.7-8
			c8.7-5.3,18.9-10.2,30.7-14.7c11.8-4.4,20.8-5.7,27-3.7c10,25.1,10.9,47.8,2.7,68c17.6,18.9,26.3,41.8,26.3,68.7
			c0,18.9-2.3,35.6-6.8,50.2c-4.6,14.6-10.4,26.2-17.7,35c-7.2,8.8-16.2,16.2-27,22.2c-10.8,6-21.2,10.3-31.3,13
			c-10.1,2.7-21.5,4.7-34.2,6c11.5,10,17.3,25.8,17.3,47.3V496c0,3.8,0.6,6.8,1.7,9.2c1.1,2.3,2.9,3.9,5.3,4.8
			c2.5,0.9,4.6,1.4,6.5,1.7c1.9,0.2,4.6,0.3,8.2,0.3H416c26.4,0,49.1-9.4,67.8-28.2C502.6,465,512,442.4,512,416V96
			C512,69.6,502.6,46.9,483.8,28.2z"
            />
            <path
              d="M101.3,372.7c-1.6,1.1-1.3,2.9,0.7,5.3c2.2,2.2,4,2.6,5.3,1c1.6-1.1,1.3-2.9-0.7-5.3C104.4,371.7,102.7,371.3,101.3,372.7
			z"
            />
            <path d="M90.3,364.3c-0.7,1.6,0.1,2.9,2.3,4c1.8,1.1,3.2,0.9,4.3-0.7c0.7-1.6-0.1-2.9-2.3-4C92.4,363,91,363.2,90.3,364.3z" />
            <path d="M111.7,385.7c-2,1.1-2,3.1,0,6c2,2.9,3.9,3.7,5.7,2.3c2-1.6,2-3.7,0-6.3C115.6,384.8,113.7,384.1,111.7,385.7z" />
            <path d="M123.3,400.7c-2,1.8-1.6,3.9,1.3,6.3c2.7,2.7,4.9,3,6.7,1c1.8-1.8,1.3-3.9-1.3-6.3C127.3,399,125.1,398.7,123.3,400.7z" />
          </svg>
        </a>
      </div> */}
    </div>
  );
};

export { contentResume, contentContact };
