import React, { useState } from "react";

import img1 from "../images/1.jpg";
import img2 from "../images/2.png";
import img3 from "../images/3.png";
import img4 from "../images/4.png";
import img5 from "../images/5.png";
import img6 from "../images/6.png";
import img7 from "../images/7.png";
import img8 from "../images/8.png";
import img9 from "../images/9.png";
import img10 from "../images/10.png";
import img11 from "../images/11.png";
import img12 from "../images/12.png";
import img13 from "../images/13.png";
import img14 from "../images/14.png";
import img16 from "../images/16.png";
import img17 from "../images/17.png";
import img18 from "../images/18.png";
import { Modal } from "@mui/material";

const imagesArray = [
  img16,
  img17,
  img18,
  img13,
  img14,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
];

export const TabGallery = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<JSX.Element>(
    <div>
      <img src={img1} alt="" />
    </div>
  );

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleModalContent = (imgsrc: string) => {
    setModalContent(<img src={imgsrc} alt="" />);
  };

  return (
    <div className="tab">
      <div className="tabIntro">
        sometimes i draw things
        <br />i like mainly working with lineart and color, drawing people,
        sci-fi and fantasy
      </div>
      <div className="imageGallery">
        {imagesArray.map((img, i) => (
          <div
            className="imageContainer"
            onClick={() => {
              handleOpen();
              handleModalContent(img);
            }}
          >
            <img src={img} alt="" />
          </div>
        ))}
        <div className="imageContainer">
          <div className="image15"></div>
        </div>
      </div>

      <Modal open={open} onClose={handleOpen}>
        <div className="galleryModal">
          <div className="modalCloseIcon" onClick={handleOpen}>
            X
          </div>
          {modalContent}
        </div>
      </Modal>
    </div>
  );
};
