import React, { useState } from "react";
import elecsun from "../images/electronicsun.png";
import signals from "../images/signals.png";

export const TabRead = () => {
    return (
        <div className="tab">
            <div className="tabRead">
                <div className="tabIntro">
                    every now and then i write poetry.
                    <br />
                    sometimes i try to collect these poems into volumes that end
                    up here
                </div>
                <a
                    className="readCard"
                    href="https://electronicsun.octavcodrea.com"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={elecsun} alt="electronic sun" />
                    <div className="cardInfo">
                        <div className="cardTitle">electronic sun</div>
                        <div className="cardDescription">
                            the texts for electronic sun were written during
                            2020 and 2022. <br />
                        </div>
                    </div>
                </a>

                <a
                    className="readCard"
                    href="https://signals.octavcodrea.com"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={signals} alt="signals in the night" />
                    <div className="cardInfo">
                        <div className="cardTitle">signals in the night</div>
                        <div className="cardDescription">
                            the texts for signals in the night were written
                            during 2015 and 2019. <br />
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
};
