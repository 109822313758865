import React from "react";
import { contentResume, contentContact } from "../Content";
import { TabGallery } from "./TabGallery";
import { TabRead } from "./TabRead";

// import './TabContainer.css';

interface TabContainerInterface {
  currentTab: number;
}

const TabContainer = (props: TabContainerInterface) => {
  switch (props.currentTab) {
    default: {
      return <div></div>;
    }

    case 0: {
      return <div>{/* home */}</div>;
    }
    case 1: {
      //resume
      return <TabRead />;
    }
    case 2: {
      //project gallery
      return <TabGallery />;
    }
    case 3: {
      //contact
      return <div>{contentContact()}</div>;
    }
  }
};

export default TabContainer;
